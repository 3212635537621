/*
 * @Description: 户型
 * @Author: 琢磨先生
 * @Date: 2022-10-06 23:36:10
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-29 11:27:35
 */
import http from "./axios_init.js";
export default {
  /**
   * 获取所有的户型
   */
  get_huxing() {
    return http.get("/seller/v1/houseStore/huxing");
  },

  /**
   * 获取户型下的所有房间
   * @returns
   */
  get_stores() {
    return http.get("seller/v1/houseStore/huxing/store");
  },

  /**
   * 户型下拉选择
   * @returns
   */
  get_cascader() {
    return http.get(`seller/v1/houseStore/cascader`);
  },
};
