<!--
 * @Description: 支出记账
 * @Author: 琢磨先生
 * @Date: 2022-11-15 15:11:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-16 11:40:14
-->
<template>
  <el-card class="box">
    <div>
      <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
        >新增</el-button
      >
    </div>
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData.data" @sort-change="onSort" border>
      <el-table-column
        prop="store.house_no"
        label="房号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="expense_item_name"
        label="费用名称"
        width="150"
      ></el-table-column>
      <el-table-column label="总额(元)" width="140">
        <template #default="scope"
          >￥{{ scope.row.decimal_total_amount }}</template
        >
      </el-table-column>
      <el-table-column label="日期" prop="date" width="120"></el-table-column>
      <el-table-column label="录入人" prop="emp_name" width="120"></el-table-column>
      <el-table-column label="备注" prop="remark" min-width="200"></el-table-column>
      <el-table-column
        prop="create_at"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="scope">
          <el-button
            type="primary"
            link
            @click="onEdit(scope.row)"
            v-if="scope.row.status === 0 || scope.row.status === 4"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>
  <!-- 新增 -->
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="800px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    draggable
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="费用项" prop="expense_item_id">
        <el-select v-model="form.expense_item_id">
          <el-option
            v-for="item in expenseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房号" prop="storeIds">
        <el-cascader 
          :options="huxing_list"
          v-model="form.storeIds"
          style="width: 300px"
          filterable
        ></el-cascader>
      </el-form-item>

      <el-form-item label="总额(元)" prop="decimal_total_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_total_amount" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="日期" prop="date">
        <el-date-picker
          v-model="form.date"
          type="date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :disabled-date="(date) => date > new Date()"
          placeholder
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="备注">
        <el-col :span="16">
          <el-input
            v-model="form.remark"
            placeholder
            type="textarea"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="凭证图片">
        <upload-gallery
          :pictures="form.pic_urls"
          @change="uploadChange"
        ></upload-gallery>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>
  
  <script>
import expense_api from "@/http/expense_api";
import UploadGallery from "../../psd/upload_gallery.vue";
import huxing_api from "@/http/huxing_api";

export default {
  components: {
    UploadGallery,
  },
  data() {
    return {
      loading: false,
      title: "",
      //显示导入弹框
      visibleDialog: false,
      saving: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //类型
      expenseList: [],
      //房型options
      huxing_list: [],
      form: {},
      rules: {
        decimal_total_amount: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        expense_item_id: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        storeIds: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    expense_api.get_expenses().then((res) => {
      if (res.code == 0) {
        this.expenseList = res.data;
      }
    });
    huxing_api.get_stores().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });

    this.loadData();
  },
  methods: {

    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
      this.loadTotal();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.query.store_id = "";
      if (this.query.storeIds) {
        this.query.store_id = this.query.storeIds[0];
      }
      this.$http.post("seller/v1/tallyBookExpense", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

        /**
     *
     * @param {*} item
     */
     onEdit(item) {
      this.title = item ? "修改" : "添加";
      if(item){
        this.form =Object.assign({},item);
        this.form.storeIds = [item.store.group_id, item.store.id];
        console.log(this.form);
      }
      this.visibleDialog = true;
    },


    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("seller/v1/tallyBookExpense/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },

    /**
     * 图片上传回调
     * @param {*} urls
     */
    uploadChange(urls) {
      this.form.pic_urls = urls;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      if (!this.form.id) {
        if (
          !Array.isArray(this.form.storeIds) ||
          this.form.storeIds.length <= 1
        ) {
          this.$message.error("请选择房间");
          return;
        }
      }
      this.form.store_id =
        this.form.storeIds[this.form.storeIds.length - 1];
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/tallyBookExpense/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>